import { IPagination } from "interfaces/IPagination";
import { API } from "services/ApiService";
import { IMobileColumns, IMobileDto } from "../model/mobile-report-interface";

export const MobileReportApi = {
	async get({
		dto, 
		limit = 50,
		offset
	} : {
		dto?: IMobileDto
		limit: number
		offset: number
		}) {
		return await API<IPagination<IMobileColumns[]>>({
			url: `/v1/reports/mobileReport`,
			method: 'post', 
			params: { offset, limit },
			data: dto
		}).then(response => response.data)
	}
}