import {
	DownloadOutlined,
	ForkOutlined,
	HistoryOutlined,
	MessageOutlined,
	PlusSquareOutlined,
	SignatureOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Dropdown, MenuProps, Modal, Row } from 'antd'
import { memo, useRef } from 'react'
import { exportService } from 'services/exportService'
import { useWbsStatusModalState } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'

import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useLocation } from 'react-router'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useAdjustmentCommentsState } from 'widgets/adjustment-comments'
import { useEstimateRelatedDocumentsState } from 'widgets/estimate-creation-from-vor/model/estimate-related-documents-state'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { adjustmentApi } from '../api/adjustment-api'
import { useAdjustmentState } from '../model/adjustment-state'

export const AdjustmentActions = memo(function () {
	const queryClient = useQueryClient()
	const { user } = useAppSelector(state => state.environment)
	const { containerType, container, setShowHistory } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			containerType: state.containerType,
			setShowHistory: state.setShowHistory,
			setExpandedRows: state.setExpandedRows
		}))
	)
	const { setContainer } = useWbsStatusModalState()
	const toggleShowComments = useAdjustmentCommentsState(
		useShallow(state => state.toggleShowComments)
	)
	const { toggleShowModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			toggleShowModal: state.toggleShowModal
		}))
	)

	const checkPermissions = useCheckPermissions()
	const { pathname } = useLocation()

	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const uploadRef = useRef<any>(null)

	const beforeApproval = async () => {
		if (container && containerType) {
			useAdjustmentState.setState({ isLoading: true })
			await adjustmentApi
				.setVisaApprove(container?.id!, containerType)
				.then(() => {
					queryClient.invalidateQueries({ queryKey: ['adjustmentContainer'] })
					queryClient.invalidateQueries({ queryKey: ['adjustmentContainerVisas'] })
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					Modal.error({
						zIndex: 999999,
						title: 'Ошибка согласования',
						content: err.response?.data.detail,
						centered: true
					})
				})
				.finally(() => useAdjustmentState.setState({ isLoading: false }))
		}
	}

	const items: MenuProps['items'] = [
		{
			key: 'main',
			label: 'Основные',
			type: 'group',
			children: [
				{
					key: 'status',
					label:
						container?.status.name === CONTAINER_STATUS.preparation
							? 'Отправить на оценку'
							: 'Статус согласования',
					icon: <SignatureOutlined />,
					disabled:
						container?.status.name === CONTAINER_STATUS.new ||
						(container?.status.name === CONTAINER_STATUS.preparation &&
							!(checkPermissions([PERMISSIONS.AdjustmentCreate]) && isAuthor)),
					onClick: async () => {
						container?.status?.name === CONTAINER_STATUS.preparation
							? await beforeApproval()
							: setContainer(container!)
					}
				}
			]
		},
		{
			key: 'helpers',
			label: 'Вспомогательные',
			type: 'group',
			children: [
				{
					key: 'chat',
					label: 'Чат',
					icon: <MessageOutlined />,
					onClick: () => toggleShowComments(true)
				},
				{
					key: 'history',
					label: 'История Было/стало',
					icon: <HistoryOutlined />,
					onClick: () => {
						setShowHistory(true)
					}
				},
				{
					key: 'relatedDocuments',
					label: 'Связанные документы',
					icon: <ForkOutlined />,
					onClick: () => toggleShowModal(true)
				}
			]
		},
		{
			key: 'importExport',
			label: `Импорт / Экспорт Было/Стало`,
			type: 'group',
			children: [
				{
					key: 'exportExcelWithPrice',
					label: 'Выгрузка с ценами',
					icon: <DownloadOutlined />,
					disabled: !checkPermissions([PERMISSIONS.AdjustmentExport]),
					onClick: () =>
						container &&
						exportService.exportWbsToExcel(
							{
								id: container?.adjustmentId!,
								name: container.name,
								createdAt: container.createdDate.toString()
							},
							containerType === 'local' ? 'local' : 'wbs',
							true,
							true
						)
				},
				{
					key: 'exportExcel',
					label: 'Выгрузка без цен',
					icon: <DownloadOutlined />,
					disabled: !checkPermissions([PERMISSIONS.AdjustmentExport]),
					onClick: () =>
						container &&
						exportService.exportWbsToExcel(
							{
								id: container?.adjustmentId!,
								name: container.name,
								createdAt: container.createdDate.toString()
							},
							containerType === 'local' ? 'local' : 'wbs',
							true
						)
				},
				{
					key: 'importWBS',
					label: 'Загрузить',
					icon: <UploadOutlined />,
					disabled: container?.ready || !checkPermissions([PERMISSIONS.AdjustmentImport]),
					onClick: () => uploadRef.current!.click()
				}
			]
		}
	]

	// const clearIsCopied = async (containerId: string, type: 'wbs' | 'local') => {
	// 	await adjustmentApi.clearIsCopied(containerId, type).then(async () => {
	// 		await queryClient
	// 			.invalidateQueries({ queryKey: ['estimateContainer'] })
	// 			.then(
	// 				async () =>
	// 					await queryClient
	// 						.invalidateQueries({ queryKey: ['estimate'] })
	// 						.then(() => setExpandedRows({}))
	// 			)
	// 	})
	// }

	return (
		<>
			<Row align="middle" justify="space-between" gutter={8} wrap={false}>
				{/* {container?.isCopied &&
					!container?.ready &&
					(currentUser?.isAdmin ||
						((container?.author.id === currentUser?.id ||
							container?.redactors.some(r => r.id === currentUser?.id)) &&
							wbsPermissions.canEdit)) && (
						<Col>
							<Button
								type="primary"
								onClick={async () => await clearIsCopied(container.id, containerType)}
							>
								Снять признак копии
							</Button>
						</Col>
					)} */}
				{/* {container?.ready && (
					<Col>
						<Tag color="volcano" style={{ fontSize: 14, padding: 8 }}>
							{containerType === 'local' ? 'Локальная смета подготовлена' : 'ВОР подготовлен'}
						</Tag>
					</Col>
				)} */}
				{/* {container?.isLocalEstimate && (
					<Col>
						<EstimateRelatedDocumentsButton type={'local'} />
					</Col>
				)} */}
				{!container?.isLocalEstimate && (
					<Col>
						<Dropdown
							trigger={['click']}
							menu={{ items, style: { minWidth: '256px' } }}
							placement="bottomRight"
						>
							<Button type="primary" icon={<PlusSquareOutlined />} style={{ minWidth: 80 }}>
								Ещё
							</Button>
						</Dropdown>
					</Col>
				)}
			</Row>
			<span style={{ display: 'none' }}>
				<EstimateImportData
					containerId={container?.id}
					disabled={container?.ready}
					trigger={uploadRef}
					registry={containerType === 'local' ? 'localEstimateRegistry' : 'wbsRegistry'}
					container={container!}
				/>
			</span>
		</>
	)
})
