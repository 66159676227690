import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, Modal, message } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useEffect, useState } from 'react'
import { useWbsRegistryState } from '..'

interface IProps {}

export const WbsCreateCorrection: FC<IProps> = props => {
	const [isLoading, setIsLoading] = useState(false)
	const queryClient = useQueryClient()
	const [hasError, setHasError] = useState(false)
	const { correctionRecord, setCorrectionRecord, onCorrectionCreate } = useWbsRegistryState()
	const [wbsCorrectionForm] = Form.useForm()

	const onCreateHandler = async (values: { name: string }) => {
		if (correctionRecord) {
			setIsLoading(true)
			await onCorrectionCreate(
				correctionRecord.id,
				values.name,
				correctionRecord.adjustmentId !== null
			)
				.then(() => {
					queryClient.invalidateQueries(['wbsRegistry'])
					setCorrectionRecord(undefined)
					message.success('Корректировка ВОР успешно создана')
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					Modal.error({
						zIndex: 999999,
						title: 'Ошибка создания корректировки ВОР',
						content: err.response?.data.detail,
						centered: true
					})
				})
				.finally(() => setIsLoading(false))
		}
	}

	useEffect(() => {
		if (correctionRecord)
			wbsCorrectionForm.setFieldValue('name', `${correctionRecord.name}_корректировка`)
	}, [correctionRecord])

	const handleFormChange = () => {
		setHasError(wbsCorrectionForm.getFieldsError().filter(({ errors }) => errors.length).length > 0)
	}

	return (
		<Modal
			destroyOnClose
			title={'Корректировка ВОР'}
			open={!!correctionRecord}
			onOk={() => wbsCorrectionForm.submit()}
			okButtonProps={{ disabled: hasError, loading: isLoading }}
			onCancel={() => {
				setCorrectionRecord(undefined)
			}}
			okText="Сохранить"
		>
			<Form
				onFinish={onCreateHandler}
				name="wbsCorrectionForm"
				form={wbsCorrectionForm}
				layout="vertical"
				onFieldsChange={handleFormChange}
			>
				<Form.Item
					name="name"
					label="Введите наименование Корректировки ВОР:"
					rules={[
						{
							required: true,
							message: 'Введите наименование Корректировки ВОР'
						},
						{ min: 3, message: 'Поле должно содержать минимум 3 символа' }
					]}
				>
					<Input.TextArea style={{ height: '80px' }} showCount maxLength={2000} />
				</Form.Item>
			</Form>
		</Modal>
	)
}
