import { Row } from '@tanstack/react-table'
import { Row as AntRow, Col } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { AdjustmentPositionMenu } from './AdjustmentPositionMenu'
import { AdjustmentPositionMove } from './AdjustmentPositionMove'

interface IProps {
	row: Row<IAdjustmentSection>
	callBack?: () => void
}

export const AdjustmentSectionTitle = memo(function (params: IProps) {
	const { row, callBack } = params
	const { user: currentUser } = useAppSelector(state => state.environment)
	const { selectedRows, container } = useAdjustmentState(
		useShallow(state => ({
			selectedRows: state.selectedRows,
			container: state.container
		}))
	)

	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false
	return (
		<AntRow
			wrap={false}
			gutter={16}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%',
				color: row.original.hasPositions ? '#0F8BFD' : '#444'
			}}
		>
			<Col onClick={() => (callBack ? callBack() : null)} style={{ width: '100%' }}>
				{row.original.codifier} - {row.original.name}
			</Col>
			{row.getIsSomeSelected() && selectedRows.parentId === row.id && (
				<Col>
					<AdjustmentPositionMove
						sectionId={row.id}
						rowId={''}
						isMultiply={true}
						row={row}
						isAuthor={isAuthor}
					/>
				</Col>
			)}
			<Col>
				<AntRow>
					<Col>
						<AdjustmentPositionMenu row={row} />
					</Col>
				</AntRow>
			</Col>
		</AntRow>
	)
})
