import { Space } from 'antd'
import { FC } from 'react'
import { TEstimateType } from 'shared/schema'
import { useAdjustmentQuery } from 'widgets/adjustment'
import { AdjustmentComments } from 'widgets/adjustment-comments'
import { AdjustmentPositionCommentsView } from 'widgets/adjustment-position-comments'
import { AdjustmentHeader } from 'widgets/adjustment/ui/AdjustmentHeader'
import { AdjustmentHistory } from 'widgets/adjustment/ui/AdjustmentHistory'
import { AdjustmentNote } from 'widgets/adjustment/ui/AdjustmentNote'
import { AdjustmentSections } from 'widgets/adjustment/ui/AdjustmentSections'
import { EstimateRelatedDocuments } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocuments'
import { EstimatePosition } from 'widgets/estimate-position-card'
import { EstimateRemoveModal } from 'widgets/estimate/ui/EstimateRemoveModal'
import { RegulatoryRequest } from 'widgets/regulatory-request'
import { WbsStatusModal } from 'widgets/wbs'

interface IProps {
	type: TEstimateType
}

export const AdjustmentPage: FC<IProps> = ({ type }) => {
	const { data, isSuccess } = useAdjustmentQuery(type)

	return (
		<>
			<WbsStatusModal type={type} isAdjustment={true} />
			<EstimateRemoveModal type={type} isAdjustment={true} />
			<EstimatePosition disabled={false} type={type} isAdjustment={true} />
			<EstimateRelatedDocuments type={type} />
			<AdjustmentHistory type={type} />
			<AdjustmentPositionCommentsView type={type} />
			<AdjustmentComments type={type} />
			<AdjustmentNote />
			<RegulatoryRequest isAdjustment={true} />
			<Space direction="vertical">
				<AdjustmentHeader type={type} />
				<AdjustmentSections type={type} />
			</Space>
		</>
	)
}
