import {
	BranchesOutlined,
	CopyOutlined,
	DeleteOutlined,
	DownloadOutlined,
	EditOutlined,
	PartitionOutlined,
	PlusSquareOutlined,
	SaveOutlined,
	StopOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { Button, Col, Dropdown, MenuProps, Row, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContainer } from 'interfaces/IContainer'
import { memo, useCallback, useRef } from 'react'
import { exportService } from 'services/exportService'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useEstimateState } from 'widgets/estimate'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { useWbsPermits, useWbsRegistryState } from '..'

interface IProps {
	record: IContainer
	editable: boolean
	isLoading: boolean
	wbsRegistryForm: FormInstance
	onSave: (record: IContainer) => void
	data?: IContainer[] | null
}
export const WbsRegistryActions = memo(function (params: IProps) {
	const checkPermissions = useCheckPermissions()
	const uploadRef = useRef<any>(null)
	const { record, editable, isLoading, wbsRegistryForm, onSave, data } = params
	const { user, permissions } = useAppSelector(state => state.environment)
	useEstimateState()
	const wbsPermissions = useWbsPermits()
	const {
		editableRow,
		setEditableRow,
		setCorrectionRecord,
		setAdjustmentRecord,
		setContainerToRemove,
		setContainerToCopy
	} = useWbsRegistryState()

	const hasAdjustment = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return !!res?.children.filter(
			c => c.adjustmentId !== null && c.status?.name !== CONTAINER_STATUS.approved
		).length
	}

	const canCreateAdjustment: boolean =
		!hasAdjustment() &&
		record.status?.name === 'Approved' &&
		(user?.isAdmin || checkPermissions([PERMISSIONS.AdjustmentCreate]))

	const canEdit = useCallback(() => {
		if (user?.isAdmin && !record.ready && record.status?.name !== CONTAINER_STATUS.approved)
			return true
		else {
			if (record.adjustmentId !== null) {
				return (
					!record.ready &&
					record.status?.name !== CONTAINER_STATUS.approved &&
					checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
					editableRow === undefined &&
					(record.author?.id === user?.id || record.redactors.some(r => r.id === user?.id))
				)
			} else {
				return (
					!record.ready &&
					wbsPermissions.canEdit &&
					editableRow === undefined &&
					(record.author?.id === user?.id || record.redactors.some(r => r.id === user?.id))
				)
			}
		}
	}, [permissions])

	const canNotCreateCorrection =
		record.isHasEstimate === true ||
		(!user?.isAdmin &&
			!(
				record.status?.name === 'Approved' &&
				(record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id))
			)) ||
		(record.status?.name !== 'Approved' && record.mainParent !== null) ||
		record.children?.length >= 1

	const mainButtons = record.adjustmentId
		? [
				{
					key: 'edit',
					label: 'Редактировать',
					icon: <EditOutlined />,
					disabled: !canEdit(),
					onClick: () => setEditableRow(record)
				},
				{
					key: 'delete',
					label: `Удалить ${record.adjustmentId ? 'документ "Было/Стало"' : 'ВОР'}`,
					danger: true,
					icon: <DeleteOutlined />,
					disabled:
						record.ready ||
						(!user?.isAdmin && (!wbsPermissions.canDelete || record.author?.id !== user?.id)),
					onClick: () => setContainerToRemove(record)
				}
		  ]
		: [
				{
					key: 'edit',
					label: 'Редактировать',
					icon: <EditOutlined />,
					disabled: !canEdit(),
					onClick: () => setEditableRow(record)
				},
				{
					key: 'copy',
					label: `Копировать ${record.adjustmentId ? 'документ "Было/Стало"' : 'ВОР'}`,
					icon: <CopyOutlined />,
					onClick: () => setContainerToCopy(record),
					disabled: !wbsPermissions.canEdit
				},
				{
					key: 'delete',
					label: `Удалить ${record.adjustmentId ? 'документ "Было/Стало"' : 'ВОР'}`,
					danger: true,
					icon: <DeleteOutlined />,
					disabled:
						record.ready ||
						(!user?.isAdmin && (!wbsPermissions.canDelete || record.author?.id !== user?.id)),
					onClick: () => setContainerToRemove(record)
				}
		  ]

	const items: MenuProps['items'] = [
		{
			key: 'main',
			label: 'Основные',
			type: 'group',
			children: mainButtons
		},
		{
			key: 'correction',
			label: `Корректировка`,
			type: 'group',
			children: [
				{
					key: 'createCorrection',
					label: (
						<Tooltip
							zIndex={99999999}
							placement="left"
							title={
								(record.author?.id === user?.id ||
									record.redactors?.some(r => r.id === user?.id)) &&
								checkPermissions([PERMISSIONS.WbsCreator]) &&
								record.status?.name !== 'Approved'
									? record.adjustmentId
										? ' Создать Корректировку ВОР можно только из документа "Было/Стало" в статусе «Согласован»'
										: 'Создать Корректировку ВОР можно только из ВОР в статусе «Завершена оценка НМТР. ВОР отправлен в ЕИС»'
									: ''
							}
						>
							Создать Корректировку ВОР
						</Tooltip>
					),
					icon: <BranchesOutlined />,
					disabled: canNotCreateCorrection,
					onClick: () => setCorrectionRecord(record)
				},
				{
					key: 'createAdjustment',
					label: 'Создать документ "Было/Стало"',
					icon: <PartitionOutlined />,
					disabled: !canCreateAdjustment,
					onClick: () => setAdjustmentRecord(record)
				}
			]
		},
		{
			key: 'importExport',
			label: `Импорт / Экспорт ${record.adjustmentId ? 'Было/Стало' : 'ВОР'}`,
			type: 'group',
			children: [
				{
					key: 'exportExcelWithPrice',
					label: 'Выгрузка с ценами',
					icon: <DownloadOutlined />,
					disabled:
						!(wbsPermissions.canExport && !record.adjustmentId) &&
						!(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
					onClick: () => {
						const dto = {
							id: record.adjustmentId ?? record.id,
							name: record.name,
							createdAt: record.createdDate
						}
						exportService.exportWbsToExcel(dto, 'wbs', record.adjustmentId ? true : false, true)
					}
				},
				{
					key: 'exportExcel',
					label: 'Выгрузка без цен',
					icon: <DownloadOutlined />,
					disabled:
						!(wbsPermissions.canExport && !record.adjustmentId) &&
						!(checkPermissions([PERMISSIONS.AdjustmentExport]) && record.adjustmentId),
					onClick: () => {
						const dto = {
							id: record.adjustmentId ?? record.id,
							name: record.name,
							createdAt: record.createdDate
						}
						exportService.exportWbsToExcel(dto, 'wbs', record.adjustmentId ? true : false)
					}
				},
				{
					key: 'importWBS',
					label: 'Загрузить',
					icon: <UploadOutlined />,
					disabled: record.ready || !wbsPermissions.canImport,
					onClick: () => uploadRef.current!.click()
				}
			]
		}
	]

	return editable ? (
		<Row gutter={2} wrap={false}>
			<Col>
				<Tooltip title="Сохранить">
					<Button
						icon={<SaveOutlined />}
						type="primary"
						loading={isLoading}
						onClick={() => {
							useWbsRegistryState.setState({ recordSave: record })
							wbsRegistryForm.submit()
						}}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Отмена">
					<Button
						icon={<StopOutlined />}
						onClick={() => setEditableRow(undefined)}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
		</Row>
	) : (
		<>
			<Dropdown
				trigger={['click']}
				menu={{
					items,
					style: { minWidth: '256px' }
				}}
				placement="bottomRight"
			>
				<Button type="link" icon={<PlusSquareOutlined />} />
			</Dropdown>
			<span style={{ display: 'none' }}>
				<EstimateImportData
					containerId={record?.id}
					disabled={record?.ready}
					trigger={uploadRef}
					registry="wbsRegistry"
				/>
			</span>
		</>
	)
})
