import {
	CheckSquareOutlined,
	CloseSquareOutlined,
	DownloadOutlined,
	ForkOutlined,
	HistoryOutlined,
	MessageOutlined,
	PlusSquareOutlined,
	PullRequestOutlined,
	SignatureOutlined,
	UploadOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Dropdown, MenuProps, Modal, Row, Tag } from 'antd'
import { WbsContainerApi } from 'pages/unmodelPage/wbsContainer'
import { memo, useRef } from 'react'
import { exportService } from 'services/exportService'
import { useEstimateCommentsState } from 'widgets/estimate-comments'
import { useRegulatoryRequestState } from 'widgets/regulatory-request'
import { useWbsPermits, useWbsStatusModalState } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'

import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useEstimateRelatedDocumentsState } from 'widgets/estimate-creation-from-vor/model/estimate-related-documents-state'
import { EstimateRelatedDocumentsButton } from 'widgets/estimate-creation-from-vor/ui/EstimateRelatedDocumentsButton'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { EstimateApi } from '../api/estimate-api'

export const EstimateContainerActions = memo(function () {
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const { containerType, container, changeReady, setShowHistory, setExpandedRows } =
		useEstimateState(
			useShallow(state => ({
				container: state.container,
				containerType: state.containerType,
				changeReady: state.changeReady,
				setShowHistory: state.setShowHistory,
				setExpandedRows: state.setExpandedRows
			}))
		)
	const { setContainer } = useWbsStatusModalState()
	const toggleShowComments = useEstimateCommentsState(useShallow(state => state.toggleShowComments))
	const RegulatoryRequestToggleShow = useRegulatoryRequestState(
		useShallow(state => state.toggleShow)
	)
	const { toggleShowModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			toggleShowModal: state.toggleShowModal
		}))
	)
	const uploadRef = useRef<any>(null)
	const wbsPermissions = useWbsPermits()
	const { user: currentUser } = useAppSelector(state => state.environment)

	const beforeApproval = async () => {
		if (container && containerType) {
			const checkContainer = await WbsContainerApi.getContainer(container.id!, containerType)
			if (checkContainer.isActual !== false) {
				await WbsContainerApi.setVisaApprove(container?.id!, containerType)
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
						queryClient.invalidateQueries({ queryKey: ['containerVisas'] })
					})
					.catch((err: AxiosError<IErrorDetail>) => {
						Modal.error({
							zIndex: 999999,
							title: 'Ошибка согласования',
							content: err.response?.data.detail,
							centered: true
						})
					})
			} else queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
		}
	}

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalExport
	const isRedactor = container?.redactors.some(r => r.id === currentUser?.id)
	const isAuthor =
		(currentUser?.isAdmin ||
			container?.author.id === currentUser?.id ||
			container?.redactors.some(r => r.id === currentUser?.id)) ??
		false

	const items: MenuProps['items'] = [
		{
			key: 'main',
			label: 'Основные',
			type: 'group',
			children: [
				{
					key: 'ready',
					label: container?.ready
						? `Отменить формирование ${containerType === 'local' ? 'Локальной сметы' : 'ВОР'}`
						: `Подготовить ${containerType === 'local' ? 'Локальную смету' : 'ВОР'}`,
					danger: container?.ready,
					icon: container?.ready ? <CloseSquareOutlined /> : <CheckSquareOutlined />,
					disabled: !wbsPermissions.canChangeReady,
					onClick: async () =>
						await changeReady().then(() => {
							queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
							// message.success('')
						})
				},
				{
					key: 'status',
					label:
						container?.status.name === 'Preparation'
							? 'Отправить на согласование'
							: 'Статус согласования',
					icon: <SignatureOutlined />,
					disabled:
						container?.status.name === 'New' ||
						(container?.status.name === 'Preparation' &&
							typeof container.isLocalEstimate === 'undefined' &&
							!(checkPermissions([PERMISSIONS.WbsCreator]) && isAuthor)) ||
						(container?.status.name === 'Preparation' &&
							container.isLocalEstimate === false &&
							!(checkPermissions([PERMISSIONS.LocalEstimateCreate]) && isAuthor)),
					onClick: async () => {
						container?.status?.name?.toLowerCase() === 'preparation'
							? await beforeApproval()
							: setContainer(container!)
					}
				}
			]
		},
		{
			key: 'helpers',
			label: 'Вспомогательные',
			type: 'group',
			children: [
				{
					key: 'request',
					label: 'Запрос в НСИ',
					icon: <PullRequestOutlined />,
					onClick: () => {
						RegulatoryRequestToggleShow(true)
					}
				},
				{
					key: 'chat',
					label: 'Чат',
					icon: <MessageOutlined />,
					onClick: () => toggleShowComments(true)
				},
				{
					key: 'history',
					label: `История ${containerType === 'local' ? 'Локальной сметы' : 'ВОР'}`,
					icon: <HistoryOutlined />,
					onClick: () => {
						setShowHistory(true)
					}
				},
				{
					key: 'relatedDocuments',
					label: 'Связанные документы',
					icon: <ForkOutlined />,
					onClick: () => toggleShowModal(true)
				}
			]
		},
		{
			key: 'importExport',
			label: `Импорт / Экспорт ${containerType === 'local' ? 'Локальной сметы' : 'ВОР'}`,
			type: 'group',
			children: [
				{
					key: 'exportExcelWithPrice',
					label: 'Выгрузка с ценами',
					icon: <DownloadOutlined />,
					disabled: !(
						(!isLocalEstimate && wbsPermissions.canExport) ||
						canEditCorrectLocalEstimate
					),
					onClick: () =>
						container &&
						exportService.exportWbsToExcel(
							{
								id: container.id,
								name: container.name,
								createdAt: container.createdDate.toString()
							},
							containerType === 'local' ? 'local' : 'wbs',
							false,
							true
						)
				},
				{
					key: 'exportExcel',
					label: 'Выгрузка без цен',
					icon: <DownloadOutlined />,
					disabled: !(
						(!isLocalEstimate && wbsPermissions.canExport) ||
						canEditCorrectLocalEstimate
					),
					onClick: () =>
						container &&
						exportService.exportWbsToExcel(
							{
								id: container.id,
								name: container.name,
								createdAt: container.createdDate.toString()
							},
							containerType === 'local' ? 'local' : 'wbs'
						)
				},
				{
					key: 'importWBS',
					label: 'Загрузить',
					icon: <UploadOutlined />,
					disabled:
						container?.ready ||
						!(
							(!isLocalEstimate && wbsPermissions.canImport) ||
							(container?.isLocalEstimate === false &&
								((wbsPermissions.canLocalImport && !wbsPermissions.canLocalExternal) ||
									(wbsPermissions.canLocalImport &&
										wbsPermissions.canLocalExternal &&
										(isRedactor || currentUser?.isAdmin))))
						),
					onClick: () => uploadRef.current!.click()
				}
			]
		}
	]

	const clearIsCopied = async (containerId: string, type: 'wbs' | 'local') => {
		await EstimateApi.clearIsCopied(containerId, type).then(async () => {
			await queryClient
				.invalidateQueries({ queryKey: ['estimateContainer'] })
				.then(
					async () =>
						await queryClient
							.invalidateQueries({ queryKey: ['estimate'] })
							.then(() => setExpandedRows({}))
				)
		})
	}

	return (
		<>
			<Row align="middle" justify="space-between" gutter={8} wrap={false}>
				{container?.isCopied &&
					!container?.ready &&
					(currentUser?.isAdmin ||
						((container?.author.id === currentUser?.id ||
							container?.redactors.some(r => r.id === currentUser?.id)) &&
							wbsPermissions.canEdit)) && (
						<Col>
							<Button
								type="primary"
								onClick={async () => await clearIsCopied(container.id, containerType)}
							>
								Снять признак копии
							</Button>
						</Col>
					)}
				{container?.ready && (
					<Col>
						<Tag color="volcano" style={{ fontSize: 14, padding: 8 }}>
							{containerType === 'local' ? 'Локальная смета подготовлена' : 'ВОР подготовлен'}
						</Tag>
					</Col>
				)}
				{container?.isLocalEstimate && (
					<Col>
						<EstimateRelatedDocumentsButton type={'local'} />
					</Col>
				)}
				{!container?.isLocalEstimate && (
					<Col>
						<Dropdown
							trigger={['click']}
							menu={{ items, style: { minWidth: '256px' } }}
							placement="bottomRight"
						>
							<Button type="primary" icon={<PlusSquareOutlined />} style={{ minWidth: 80 }}>
								Ещё
							</Button>
						</Dropdown>
					</Col>
				)}
			</Row>
			<span style={{ display: 'none' }}>
				<EstimateImportData
					containerId={container?.id}
					disabled={container?.ready}
					trigger={uploadRef}
					registry={containerType === 'local' ? 'localEstimateRegistry' : 'wbsRegistry'}
					container={container}
				/>
			</span>
		</>
	)
})
