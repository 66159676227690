import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Form, Row, Select, Table, Tag, Tooltip, message } from 'antd'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { useWorkPackages } from 'hooks/useWorkPackage'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useState } from 'react'
import { IWorkPackage, workPackageService } from 'services/workPackageService'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	children: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({ editing, children, ...restProps }) => {
	const { issueTypes } = useAppSelector(state => state.environment)
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name="issueTypes" style={{ margin: 0 }}>
					<Select
						mode="multiple"
						options={issueTypes
							?.filter(o => !o.isDeleted)
							.map(o => ({
								key: o.id,
								value: o.id,
								label: o.name
							}))}
					/>
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const WorkPackagesGuide: FC = () => {
	const queryClient = useQueryClient()
	const { data, isFetching } = useWorkPackages()
	const [workPackageForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IWorkPackage) => record.id === editingKey
	const columns = [
		{
			dataIndex: 'code',
			title: 'Порядковый номер'
		},
		{
			dataIndex: 'name',
			title: 'Наименование'
		},
		{
			width: '60%',
			title: 'Типы замечаний',
			render: (_: any, record: IWorkPackage) =>
				record.issueTypes
					.filter(it => !it.isDeleted)
					.map(it => (
						<Tag
							color={'#1677ff'}
							style={{
								fontSize: 14,
								padding: 4,
								margin: 3,
								color: '#f0f0f0'
							}}
						>
							{it.name}
						</Tag>
					)),
			editable: true
		},
		{
			width: 64,
			align: 'center',
			render: (_: any, record: IWorkPackage) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									type="primary"
									onClick={() => save(record)}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => cancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button
							icon={<EditOutlined />}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						/>
					</Tooltip>
				)
			}
		}
	]

	const save = async (data: IWorkPackage) => {
		const dto = {
			id: data.id,
			issueTypesId: workPackageForm.getFieldValue('issueTypes')
		}
		await workPackageService
			.setIssueTypes(dto.id, dto.issueTypesId)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['appShared'] })
				cancel()
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				message.error(err.response?.data.detail ?? 'Произошла ошибка.')
			)
	}

	const edit = (record: Partial<IWorkPackage>) => {
		workPackageForm.setFieldsValue({
			issueTypes: record.issueTypes?.flatMap(it => it.id)
		})
		setEditingKey(record.id!)
	}

	const cancel = () => {
		setEditingKey('')
	}

	const mergedColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: IWorkPackage) => ({
				editing: isEditing(record)
			})
		}
	})

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Form form={workPackageForm} component={false}>
				<Table
					loading={isFetching}
					components={{
						body: {
							cell: EditableCell
						}
					}}
					className="app-table-types"
					rowKey={record => record.id}
					size="small"
					pagination={false}
					bordered
					dataSource={data}
					columns={mergedColumns as any}
				/>
			</Form>
		</div>
	)
}
