import { ClockCircleOutlined, CloseCircleOutlined, SignatureOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Card, Col, Descriptions, Modal, Row, Space, Tag, Tooltip } from 'antd'
import { Popconfirm } from 'antd/lib'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import {
	IStatusRouteItemVisaState,
	WbsContainerApi,
	useWbsContainerState
} from 'pages/unmodelPage/wbsContainer'
import { useCanVisaApprove } from 'pages/unmodelPage/wbsContainer/model/wbsContainerQuery'

import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { LocalEstimateContainerApi } from 'pages/unmodelPage/localEstimate/api/localEstimateApi'
import {
	useCanVisaApproveLocalEstimate,
	useContainerLocalEstimateVisas
} from 'pages/unmodelPage/localEstimate/model/localEstimateQuery'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CheckDeclension } from 'shared/helpers'
import { adjustmentApi } from 'widgets/adjustment'
import { useAdjustmentCommentsState } from 'widgets/adjustment-comments'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useEstimateState } from 'widgets/estimate'
import { useEstimateCommentsState } from 'widgets/estimate-comments'
import { useShallow } from 'zustand/react/shallow'
import { useContainerVisas } from '../model/wbs-queries'
import { useWbsStatusModalState } from '../model/wbs-status-modal-state'

interface IWbsStatuses {
	id: string
	group: string
	title: string
	color: string
	order: number
	author?: string
	dateTransfer: Date | null
	duration?: string
	items: IWbsStatusesItem[]
}

interface IWbsStatusesItem {
	id: string
	title: string
	state: boolean
	approver: IStatusRouteItemVisaState | null
}

interface IProp {
	type: 'wbs' | 'local'
	isAdjustment?: boolean
}

export const WbsStatusModal: FC<IProp> = ({ type, isAdjustment }) => {
	const state = isAdjustment ? useAdjustmentState : useEstimateState
	const commentState = isAdjustment ? useAdjustmentCommentsState : useEstimateCommentsState
	const api = isAdjustment
		? adjustmentApi
		: type === 'local'
		? LocalEstimateContainerApi
		: WbsContainerApi
	const { id: containerId } = useParams()
	const queryClient = useQueryClient()
	const [isLoading, setIsLoading] = useState(false)
	const { container } = state()
	const { show, unset, container: containerModal } = useWbsStatusModalState()
	const toggleShowComments = commentState(useShallow(state => state.toggleShowComments))
	const { data: containerVisas } = useContainerVisas(
		containerModal && type === 'wbs'
			? containerModal.id
			: container && type === 'wbs'
			? container?.id
			: undefined,
		(containerModal && typeof containerModal?.adjustmentId === 'string') || isAdjustment
	)
	const { data: localEstimateVisas } = useContainerLocalEstimateVisas(
		container && type === 'local' && container.isLocalEstimate === false ? container.id : undefined,
		(containerModal && typeof containerModal?.adjustmentId === 'string') || isAdjustment
	)
	const { data: canApprove } = useCanVisaApprove(
		container && type === 'wbs' ? container.id : containerModal?.id,
		type,
		isAdjustment ? true : false
	)
	const { data: canApproveLocalEstimate } = useCanVisaApproveLocalEstimate(
		container && type === 'local' && container.isLocalEstimate === false ? container.id : undefined,
		isAdjustment ? true : false
	)
	const { user } = useAppSelector(state => state.environment)
	const [wbsStatuses, setWbsStatuses] = useState<IWbsStatuses[]>([])

	// useEffect(() => {
	// 	if (containerModal?.id) {
	// 		isAdjustment === true
	// 			? adjustmentApi
	// 					.get(containerModal?.id!, type)
	// 					.then(data => useWbsStatusModalState.setState({ container: data }))
	// 					.catch((err: AxiosError<IErrorDetail>) => err)
	// 			: WbsContainerApi.getContainer(containerModal?.id!, type)
	// 					.then(data => useWbsStatusModalState.setState({ container: data }))
	// 					.catch((err: AxiosError<IErrorDetail>) => err)
	// 	}
	// }, [wbsStatuses])

	useEffect(() => {
		if (show && container?.id === containerId) {
			type === 'local'
				? queryClient.invalidateQueries(
						isAdjustment ? ['adjustmentLocalEstimateVisas'] : ['localEstimateVisas']
				  )
				: queryClient.invalidateQueries(
						isAdjustment ? ['adjustmentContainerVisas'] : ['containerVisas']
				  )
		}
	}, [show])

	useEffect(() => {
		setWbsStatuses(
			(type === 'wbs' ? containerVisas : localEstimateVisas)?.map(s => ({
				id: s.id,
				group: s.name,
				title: s.description,
				color: s.color,
				order: s.order,
				author: s.author,
				duration: s.duration,
				dateTransfer: s.dateTransfer && dayjs(s.dateTransfer).isValid() ? s.dateTransfer : null,
				items:
					s.visas?.map(v => ({
						id: v.id,
						title: v.name,
						state: v.visaState !== null,
						approver: v.visaState,
						duration: v.duration
					})) ?? []
			})) ?? []
		)
	}, [containerVisas, localEstimateVisas])

	const hoursTitle = (number: number) => {
		const hourTitles = ['час', 'часа', 'часов']
		if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return hourTitles[2]
		let last_num = number % 10
		if (last_num === 1) return hourTitles[0]
		if ([2, 3, 4].includes(last_num) || number === 0) return hourTitles[1]
		if ([5, 6, 7, 8, 9, 0].includes(last_num)) return hourTitles[2]
	}
	const dateDifference = (date1: any, date2: any) => {
		const firstDate = dayjs(date1).isValid() ? dayjs(date1) : dayjs()
		const secondDate = dayjs(date2).isValid() ? dayjs(date2) : dayjs()
		const diff = secondDate.diff(firstDate, 'hours')
		return `${diff > 0 ? diff : 'менее'} ${hoursTitle(diff)}`
	}

	return (
		<Modal
			zIndex={9999999}
			centered
			width={1000}
			footer={false}
			open={show}
			onCancel={unset}
			title="Статус согласования"
			destroyOnClose={true}
		>
			<Space
				direction="vertical"
				style={{ width: '100%', maxHeight: '80vh', overflow: 'scroll', padding: '1rem' }}
				size="large"
			>
				{wbsStatuses
					.sort((a, b) => (b.group !== null ? 1 : 0) - (a.group !== null ? 1 : 0))
					.map((status, index) => (
						<Card
							title={
								<Row justify="space-between" gutter={16}>
									<Col flex={1}>{status.title}</Col>
									<Col>{status.author}</Col>
									{status.dateTransfer !== null && (
										<Col>{dayjs(status.dateTransfer).format('HH:mm DD.MM.YYYY')}</Col>
									)}
									{dayjs(status.dateTransfer).isValid() && (
										<Col>
											<Tooltip
												zIndex={999999}
												title={
													index === wbsStatuses.length - 1
														? 'Общее время согласования'
														: 'Время нахождения в этом статусе'
												}
											>
												<Tag
													icon={<ClockCircleOutlined />}
													color={status.group === 'New' ? '#757575' : '#' + status.color}
												>
													{type === 'local'
														? index === wbsStatuses.length - 1
															? dateDifference(
																	wbsStatuses.find(s => s.order === 1)?.dateTransfer!,
																	status.dateTransfer
															  )
															: dateDifference(
																	status.dateTransfer,
																	wbsStatuses.find(s => s.order === status.order + 1)?.dateTransfer!
															  )
														: status?.duration !== undefined
														? status?.duration!?.slice(0, 5).includes('.')
															? `${status?.duration!?.split('.')[0]} ${CheckDeclension(
																	+status?.duration!?.split('.')[0],
																	['день', 'дня', 'дней']
															  )} ${status
																	?.duration!.split('.')[1]
																	?.slice(0, 2)} ${CheckDeclension(
																	+status?.duration!.split('.')[1]?.slice(0, 2),
																	['час', 'часа', 'часов']
															  )}`
															: status?.duration!?.slice(0, 2) !== '00'
															? `${status?.duration!?.slice(0, 2)} ${CheckDeclension(
																	+status?.duration!?.slice(0, 2),
																	['час', 'часа', 'часов']
															  )}`
															: 'менее часа'
														: null}
												</Tag>
											</Tooltip>
										</Col>
									)}
								</Row>
							}
							key={status.id}
							className="at-workcard"
							styles={{
								body: {
									display: !!status.items?.length ? 'block' : 'none'
								}
							}}
							style={{
								background: '#' + status.color + '10',
								border:
									status.id === container?.status?.id || status.id === containerModal?.status?.id
										? '2px solid #' + status.color
										: '1px solid #ddd'
							}}
						>
							{status.items?.length && (
								<Descriptions
									layout="horizontal"
									size="small"
									column={2}
									bordered
									className="at-descriptions"
									labelStyle={{
										width: '30%',
										fontWeight: 'bold',
										alignItems: 'center',
										padding: '4px 8px'
									}}
									contentStyle={{
										alignItems: 'center'
									}}
								>
									{status.items?.map(item => (
										<Descriptions.Item key={item.id} label={item.title! + ':'} span={2}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												{item.state ? (
													<>
														<Space direction="vertical" key={item.approver?.userId}>
															<b>{item.approver?.userFullName}</b>
															<Col>
																<i>
																	{dayjs(item.approver?.approveDate).isValid()
																		? dayjs(item.approver?.approveDate).format('HH:mm DD.MM.YYYY')
																		: '-'}
																</i>
																<Tag
																	icon={<ClockCircleOutlined />}
																	style={{ marginLeft: 10 }}
																	color={status.group === 'New' ? '#757575' : '#' + status.color}
																>
																	{item.approver?.duration!?.slice(0, 5).includes('.')
																		? `${item.approver?.duration!?.split('.')[0]} ${CheckDeclension(
																				+item.approver?.duration!?.split('.')[0],
																				['день', 'дня', 'дней']
																		  )} ${item.approver
																				?.duration!?.split('.')[1]
																				?.slice(0, 2)} ${CheckDeclension(
																				+item.approver?.duration!?.split('.')[1]?.slice(0, 2),
																				['час', 'часа', 'часов']
																		  )}`
																		: item.approver?.duration!?.slice(0, 2) !== '00'
																		? `${item.approver?.duration!?.slice(0, 2)} ${CheckDeclension(
																				+item.approver!?.duration!?.slice(0, 2),
																				['час', 'часа', 'часов']
																		  )}`
																		: 'менее часа'}
																</Tag>
															</Col>
														</Space>
														{(status.id === container?.status?.id ||
															status.id === containerModal?.status?.id) &&
															(item.approver?.userId === user?.id || user?.isAdmin) && (
																<Popconfirm
																	zIndex={9999999999999}
																	title="Снять визу?"
																	onConfirm={async () =>
																		await api
																			.unsetVisaApprove(
																				container ? container?.id! : containerModal?.id!,
																				type,
																				item.id
																			)
																			.then(() => {
																				queryClient.invalidateQueries({
																					queryKey: [
																						isAdjustment
																							? 'adjustmentContainer'
																							: // : type === 'local'
																							  // ? 'localEstimateContainer'
																							  'estimateContainer'
																					]
																				})

																				queryClient.invalidateQueries({
																					queryKey: [
																						isAdjustment
																							? type === 'local'
																								? 'adjustmentLocalEstimateVisas'
																								: 'adjustmentContainerVisas'
																							: type === 'local'
																							? 'localEstimateVisas'
																							: 'containerVisas'
																					]
																				})

																				queryClient.invalidateQueries({ queryKey: ['asm'] })
																				queryClient.invalidateQueries({
																					queryKey: [
																						type === 'local'
																							? 'localEstimateRegistry'
																							: 'wbsRegistry'
																					]
																				})
																			})
																			.catch((error: AxiosError<IErrorDetail>) =>
																				modal.error({
																					title: 'Произошла ошибка',
																					content:
																						error.response?.data?.detail ??
																						'Непредвиденная ошибка. Обратитесь к администратору'
																				})
																			)
																	}
																	okText="Подтвердить"
																	cancelText="Отмена"
																>
																	<Button danger ghost icon={<CloseCircleOutlined />}>
																		Отменить
																	</Button>
																</Popconfirm>
															)}
													</>
												) : (
													((container?.status?.id === status.id &&
														container.status.name !== 'Approved') ||
														(containerModal?.status?.id === status.id &&
															containerModal.status.name !== 'Approved')) &&
													((type === 'wbs' ? canApprove : canApproveLocalEstimate)?.includes(
														item.id
													) ||
														user?.isAdmin) && (
														<Button
															loading={isLoading}
															icon={<SignatureOutlined />}
															onClick={async () => {
																setIsLoading(true)
																await api
																	.setVisaApprove(
																		container ? container?.id! : containerModal?.id!,
																		type,
																		item.id
																	)
																	.then(() => {
																		queryClient.invalidateQueries({
																			queryKey: [
																				isAdjustment
																					? 'adjustmentContainer'
																					: // : type === 'local'
																					  // ? 'localEstimateContainer'
																					  'estimateContainer'
																			]
																		})

																		queryClient.invalidateQueries({
																			queryKey: [
																				isAdjustment
																					? type === 'local'
																						? 'adjustmentLocalEstimateVisas'
																						: 'adjustmentContainerVisas'
																					: type === 'local'
																					? 'localEstimateVisas'
																					: 'containerVisas'
																			]
																		})

																		queryClient.invalidateQueries({ queryKey: ['asm'] })
																		queryClient.invalidateQueries({
																			queryKey: [
																				type === 'local' ? 'localEstimateRegistry' : 'wbsRegistry'
																			]
																		})
																	})
																	.catch((error: AxiosError<IErrorDetail>) =>
																		modal.confirm({
																			okText: error.response?.data.detail
																				.toLowerCase()
																				.includes('чат')
																				? 'Чаты'
																				: 'Ок',
																			zIndex: 9999999,
																			okButtonProps: {
																				style: {
																					display: containerId ? 'inline-block' : 'none'
																				}
																			},
																			cancelText: 'Закрыть',
																			onOk: () => {
																				if (containerId) {
																					if (
																						error.response?.data.detail
																							.toLowerCase()
																							.includes('чат')
																					) {
																						unset()
																						useWbsContainerState.setState({
																							showContainerCommentsDrawer: true
																						})
																						toggleShowComments(true)
																					} else {
																						queryClient.invalidateQueries({
																							queryKey: [
																								isAdjustment
																									? 'adjustmentContainer'
																									: type === 'local'
																									? 'localEstimateContainer'
																									: 'wbsContainer'
																							]
																						})
																						queryClient.invalidateQueries({
																							queryKey: [
																								isAdjustment
																									? 'adjustmentContainerVisas'
																									: type === 'local'
																									? 'localEstimateVisas'
																									: 'containerVisas'
																							]
																						})
																						queryClient.invalidateQueries({ queryKey: ['asm'] })
																						queryClient.invalidateQueries({
																							queryKey: [
																								type === 'local' ? 'localEstimates' : 'wbsRegistry'
																							]
																						})
																					}
																				}
																			},
																			content: (
																				<p>
																					{error.response?.data?.detail ??
																						'Необходимо по ВОР закрыть все свои чаты'}
																				</p>
																			)
																		})
																	)
																	.finally(() => setIsLoading(false))
															}}
														>
															Согласовать
														</Button>
													)
												)}
											</div>
										</Descriptions.Item>
									))}
								</Descriptions>
							)}
						</Card>
					))}
			</Space>
		</Modal>
	)
}
