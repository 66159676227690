import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import {
	Badge,
	Button,
	Card,
	Checkbox,
	Col,
	Descriptions,
	Empty,
	Popconfirm,
	Row,
	Tooltip,
	Typography,
	message
} from 'antd'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IIssue } from 'interfaces/IIssue'
import { IOrdinance, IOrdinanceIssue } from 'interfaces/IOrdinance'
import IssueDrawer from 'pages/issuesPage/issueDrawer'
import SignModal from 'pages/ordinancePage/SignModal'
import { FC, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { deleteAct } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { SignBlock } from '../SignBlock'
import FileViewer from '../fileViewer'

interface IOrdinanceIssuesProps {
	ordinance: IOrdinance
	canDeleteFiles: boolean
	issues: IOrdinanceIssue[]
	issuesForAct: string[]
	// onDeleteAttachment: (type: 'ordinance' | 'issue', attachmentId: string, issueId: string) => void
	onDeleteAct: () => void
	onIssuesSelect: (issue: string) => void
	onChange: () => void
	onUpdate: () => void
}
const { Text } = Typography

export const OrdinanceIssues: FC<IOrdinanceIssuesProps> = ({
	ordinance,
	canDeleteFiles,
	issues,
	issuesForAct,
	onChange,
	// onDeleteAttachment,
	onDeleteAct,
	onIssuesSelect,
	onUpdate
}) => {
	const checkPermissions = useCheckPermissions()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [showViewer, setShowViewer] = useState(false)
	const [selectedIssue, setSelectedIssue] = useState<IIssue | null>(null)
	const [showIssueDrawer, setShowIssueDrawer] = useState(false)
	const [signShow, setSignShow] = useState(false)
	const [attachmentType, setAttachmentType] = useState(2)
	const [signingFileId, setSigningFileId] = useState<string>()
	const { permissions } = useAppSelector(state => state.environment)
	const canApprove = checkPermissions([PERMISSIONS.OrdinanceApproval])

	const badgeStatusColor = (status: string) => {
		switch (status) {
			case 'ready_to_inspect':
				return '#7A77D9'
			case 'not approved':
			case 'in dispute':
				return '#FF142D'
			case 'open':
				return '#FFAD14'
			case 'work completed':
			case 'work_completed':
			case 'answered':
				return '#0770C5'
			case 'draft':
				return '#9DAEAF'
			default:
				return '#8099A8'
		}
	}

	const onPreviewOpen = (issue: IIssue) => {
		setSelectedIssue(issue)
		setShowViewer(true)
	}

	const onPreviewClose = () => {
		setSelectedIssue(null)
		setShowViewer(false)
	}

	const onIssueDrawerClose = (show: boolean, mustUpdate: boolean, issueId?: string) => {
		setSelectedIssue(null)
		setShowIssueDrawer(false)
		if (mustUpdate) {
			onChange()
		}
	}

	const onSigning = (type: number, actId?: string) => {
		setAttachmentType(type)
		setSigningFileId(actId)
		setSignShow(true)
	}

	const downloadAct = async (issue: IOrdinanceIssue, actType: string) => {
		const act: { id: string; name: string } | null =
			Object.entries(issue).find(item => item[0] === actType)?.[1] || null
		if (act !== null)
			await downloadFile(act.id)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					const extension =
						ordinance!?.attachments!?.find(file => file.id === act!?.id!)?.extension! || '.zip'
					link.href = href
					link.setAttribute('download', act!?.name! + extension)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch(() => message.error('Во время загрузки файла произошла ошибка'))
		else message.error('Файл не найден')
	}

	return (
		<>
			<FileViewer show={showViewer} onClose={onPreviewClose} src={selectedIssue} />
			<IssueDrawer
				drawerOpen={showIssueDrawer}
				currentIssue={selectedIssue!?.id!}
				updateList={onUpdate}
				onDrawerClose={onIssueDrawerClose}
				opacity={0}
			/>
			<SignModal
				ordinance={ordinance}
				show={signShow}
				attachmentType={attachmentType}
				signingFileId={signingFileId}
				onClose={() => setSignShow(false)}
				onConfirm={onUpdate}
			/>
			{issues! && issues!?.length > 0 ? (
				issues
					.sort((a, b) => a.number - b.number)
					.map((issue, index) => (
						<Card className="at-workcard" key={issue!?.id!}>
							<Row align="middle" gutter={8} wrap={false}>
								{checkPermissions([PERMISSIONS.OrdinanceCreateEliminationAct]) && (
									<Col>
										<Tooltip
											title={
												issue!?.issueStatus!?.identityName! === 'open'
													? 'По открытым замечаниям акты нельзя формировать, нужно закрыть Замечание.'
													: issue!?.issueStatus!?.identityName! === 'closed' &&
													  (issue.isActFormed || issue!?.eliminationAct! !== null)
													? 'Акт уже был сформирован. Если файла нет, обратитесь в тех поддержку.'
													: null
											}
										>
											<Checkbox
												data-issue-box={index + 1}
												disabled={
													issue!?.issueStatus!?.identityName! !== 'closed' ||
													issue!?.eliminationAct! !== null ||
													issue.isActFormed
												}
												checked={issuesForAct!?.some(row => row === issue!?.id!)}
												onChange={() => onIssuesSelect(issue!?.id!)}
											/>
										</Tooltip>
									</Col>
								)}
								<Col flex={1}>
									<Badge.Ribbon
										text={<Tooltip title="Статус замечания">{issue!?.issueStatus!?.name!}</Tooltip>}
										color={badgeStatusColor(issue!?.issueStatus!?.identityName!)}
									>
										<Descriptions
											layout="horizontal"
											column={12}
											bordered
											className="at-descriptions"
											labelStyle={{
												fontWeight: 'bold',
												width: '20%',
												alignItems: 'center',
												padding: '4px 8px'
											}}
											contentStyle={{
												width: '80%',
												padding: '4px 8px'
											}}
										>
											<Descriptions.Item label="Номер замечания:" span={12}>
												<Text>{issue!?.number!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Наименование:" span={12}>
												{checkPermissions([PERMISSIONS.IssueView]) ? (
													<Button
														type="link"
														style={{
															padding: 0,
															margin: 0,
															whiteSpace: 'pre-wrap',
															textAlign: 'left'
														}}
														onClick={() => {
															setSelectedIssue(issue)
															setShowIssueDrawer(true)
														}}
													>
														{issue!?.title!}
													</Button>
												) : (
													issue!?.title!
												)}
											</Descriptions.Item>
											<Descriptions.Item label="Владелец:" span={12}>
												<Text>{issue!?.owner!?.name!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Генподрядчик:" span={12}>
												<Text>{issue!?.contractor?.name!}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Субподрядчик:" span={12}>
												<Text>
													{issue!?.subcontractor !== null ? issue!?.subcontractor?.name! : '-'}
												</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Дата создания:" span={12}>
												<Text>{dayjs(issue!?.createdAt!).format('DD.MM.YYYY')}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Дата устранения:" span={12}>
												<Text>{dayjs(issue!?.dueDate!).format('DD.MM.YYYY')}</Text>
											</Descriptions.Item>
											<Descriptions.Item label="Акт об устранении:" span={12}>
												{issue.eliminationAct !== null && (
													<Row align="middle" gutter={8} wrap={false}>
														<Col flex={1}>
															<Button
																className="at-btn-wrap"
																style={{
																	padding: 0,
																	margin: 0,
																	whiteSpace: 'pre-wrap',
																	textAlign: 'left'
																}}
																type="link"
																onClick={() => downloadAct(issue, 'eliminationAct')}
															>
																{issue!?.eliminationAct!?.name!}
															</Button>
														</Col>
														{issue!?.eliminationAct !== null && (
															<Col>
																<SignBlock
																	actId={issue!?.eliminationAct!?.id!}
																	ordinance={ordinance}
																	typeId={2}
																	onClick={(type, actId) => onSigning(type, actId)}
																/>
															</Col>
														)}
														{canDeleteFiles &&
															ordinance?.attachments?.find(file =>
																issue!?.eliminationAct!?.id
																	? file!?.id! === issue!?.eliminationAct!?.id!
																	: file!?.typeId! === 2
															)?.statusId! !== 4 && (
																<Col>
																	<Popconfirm
																		title="Удалить?"
																		placement="topRight"
																		onConfirm={async () =>
																			// onDeleteAttachment('issue', issue!?.eliminationAct!?.id!, issue?.id!)
																			await deleteAct(
																				ordinance!?.id,
																				issue!?.eliminationAct?.id
																			).then(() => {
																				onDeleteAct()
																			})
																		}
																		okButtonProps={{ danger: true }}
																		okText="Да"
																		cancelText="Отмена"
																	>
																		<Button icon={<DeleteOutlined />} danger />
																	</Popconfirm>
																</Col>
															)}
													</Row>
												)}
											</Descriptions.Item>
											<Descriptions.Item label="Акт о неустранении:" span={12}>
												{issue!?.nonEliminationAct !== null && (
													<Row align="middle" gutter={8} wrap={false}>
														<Col flex={1}>
															<Button
																disabled={
																	!(
																		ordinance?.attachments?.find(
																			file => file?.id === issue?.nonEliminationAct?.id
																		)?.statusId === 2 ||
																		ordinance?.attachments?.find(
																			file => file?.id === issue?.nonEliminationAct?.id
																		)?.statusId === 4 ||
																		(ordinance?.attachments?.find(
																			file => file?.id === issue?.nonEliminationAct?.id
																		)?.statusId === 5 &&
																			(currentUser?.id === ordinance?.createdBy?.id ||
																				currentUser?.isAdmin ||
																				canApprove))
																	)
																}
																className="at-btn-wrap"
																style={{
																	padding: 0,
																	margin: 0,
																	whiteSpace: 'pre-wrap',
																	textAlign: 'left'
																}}
																type="link"
																onClick={() => downloadAct(issue, 'nonEliminationAct')}
															>
																{issue!?.nonEliminationAct!?.name!}
															</Button>
														</Col>
														{issue!?.nonEliminationAct !== null &&
															(ordinance?.attachments?.find(
																file => file.id === issue?.nonEliminationAct?.id
															)?.statusId === 2 ||
																ordinance?.attachments?.find(
																	file => file?.id === issue?.nonEliminationAct?.id
																)?.statusId === 4 ||
																(ordinance?.attachments?.find(
																	file => file?.id === issue?.nonEliminationAct?.id
																)?.statusId === 5 &&
																	(currentUser?.id === ordinance?.createdBy?.id ||
																		currentUser?.isAdmin ||
																		canApprove))) && (
																<Col>
																	<SignBlock
																		actId={issue!?.nonEliminationAct!?.id!}
																		ordinance={ordinance}
																		typeId={3}
																		onClick={(type, actId) => onSigning(type, actId)}
																	/>
																</Col>
															)}
														{canDeleteFiles &&
															ordinance?.attachments?.find(file =>
																issue!?.nonEliminationAct!?.id
																	? file!?.id! === issue!?.nonEliminationAct!?.id!
																	: file!?.typeId! === 2
															)?.statusId! !== 4 && (
																<Col>
																	<Popconfirm
																		title="Удалить?"
																		placement="topRight"
																		onConfirm={async () =>
																			// onDeleteAttachment('issue', issue!?.nonEliminationAct!?.id!, issue?.id!)
																			await deleteAct(
																				ordinance!?.id,
																				issue!?.nonEliminationAct?.id
																			).then(() => {
																				onDeleteAct()
																			})
																		}
																		okButtonProps={{ danger: true }}
																		okText="Да"
																		cancelText="Отмена"
																	>
																		<Button icon={<DeleteOutlined />} danger />
																	</Popconfirm>
																</Col>
															)}
													</Row>
												)}
											</Descriptions.Item>
											<Descriptions.Item label="Номер пункта нормативного акта:" span={12}>
												<Text>{issue!?.actPoints!}</Text>
											</Descriptions.Item>
										</Descriptions>
										{issue!?.attachmentsCount! > 0 && (
											<Row justify="end" style={{ marginTop: '1rem' }}>
												<Button
													icon={<EyeOutlined rev={undefined} />}
													onClick={() => onPreviewOpen(issue!)}
												>
													Файлы
												</Button>
											</Row>
										)}
									</Badge.Ribbon>
								</Col>
							</Row>
						</Card>
					))
			) : (
				<Empty />
			)}
		</>
	)
}
